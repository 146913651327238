<template>
  <div class="content-box" v-loading="loading">
    <!-- <Else></Else> -->
    <div class="brand-list-title" style="border-bottom:1px solid #D8D8D8;display:flex;justify-content: space-between;">
      <div style="display:inline-block;border-bottom:1px solid #323232;font-size:18px;">商标详情</div>
      <div>{{ companyName }}</div>
    </div>
    <div>
      <div class="row-title">申请信息</div>
      <el-row type="flex" justify="space-between">
        <div class="row-item">
          <div class="row-label">商标图形</div>
          <div class="row-value">
            <el-image
              v-if="info.trademarkImgUrl"
              style="width:80px;height:80px;"
              fit="contain"
              :preview-src-list="[info.trademarkImgUrl]"
              :src="info.trademarkImgUrl"></el-image>
          </div>
        </div>
        <div class="row-tag" v-if="info.trademarkStatus">
          {{ info.trademarkStatus }}
        </div>
      </el-row>
      <el-row type="flex">
        <el-col :span="8" class="row-item">
          <div class="row-label">商标名称</div>
          <div class="row-value">{{ info.trademarkName }}</div>
        </el-col>
        <el-col :span="8" class="row-item">
          <div class="row-label">申请/注册号</div>
          <div class="row-value">{{ info.regnum || '暂无' }}</div>
        </el-col>
        <el-col :span="8" class="row-item">
          <div class="row-label">申请日期</div>
          <div class="row-value">{{ info.applicationDate || '暂无' }}</div>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="8" class="row-item">
          <div class="row-label">申请人</div>
          <div class="row-value">{{ info.applicationPeople || '暂无' }}</div>
        </el-col>
        <el-col :span="16" class="row-item">
          <div class="row-label">申请人地址</div>
          <div class="row-value">{{ info.applicationAddr || '暂无' }}</div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row type="flex">
        <el-col :span="8" class="row-item">
          <div class="row-label">初审公告日期</div>
          <div class="row-value">{{ info.instanceTime || '暂无' }}</div>
        </el-col>
        <el-col :span="8" class="row-item">
          <div class="row-label">初审公告号</div>
          <div class="row-value">{{ info.AnnouncementInstance || '暂无' }}</div>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="8" class="row-item">
          <div class="row-label">注册公告日期</div>
          <div class="row-value">{{ info.registerTime || '暂无' }}</div>
        </el-col>
        <el-col :span="8" class="row-item">
          <div class="row-label">注册公告号</div>
          <div class="row-value">{{ info.AnnouncementRegister || '暂无' }}</div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row type="flex">
        <el-col :span="8" class="row-item">
          <div class="row-label">专用权期限</div>
          <div class="row-value">{{ info.privateTime || '暂无' }}</div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="row-title">商标信息</div>
      <el-row type="flex">
        <el-col :span="8" class="row-item">
          <div class="row-label">是否共有商标</div>
          <div class="row-value">{{ info.publicTrademark || '暂无' }}</div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <div class="row-label">商标/服务项目</div>
      </el-row>
      <el-row>
        <el-col v-for="(item,index) in info.tm_detail" :span="8" :key="index"
                style="color:#FB602D;margin-bottom: 12px;">
          <span style="margin-right:10px;">{{ item.DetailType }}</span>
          <span>{{ item.DetailName }}</span>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row type="flex">
        <el-col :span="24" class="row-item">
          <div class="row-label">代理/代办机构</div>
          <div class="row-value">{{ info.agentName }}</div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="row-title">商标状态流程</div>
      <div>
        <div v-for="(item,index) in info.trademarkPocessList" :key="index" class="process-content">
          <i class="point"></i>
          {{ item.FlowDate }}
          {{ item.FlowName }}
        </div>
      </div>
      <el-divider></el-divider>
      <div class="row-title">商标公告</div>
      <div>
        <div v-for="(item,index) in info.trademarkAnnouncementList"
             style="cursor:pointer;margin-bottom:16px;"
             :key="index" @click="clickHandle(item)">
          <span style="margin-right:20px;">
            {{ item.AnnouncementPeriod }}
          </span>
          <span style="color:#1777FF">
          第{{ item.TrialNum + '期 ' }}
          {{ item.AnnouncementType }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {knowledgeAnnouncement, knowledgeDetail} from "../../api/api";
// import Else from '../ui/else.vue';
export default {
  name: "brandDetail",
  data() {
    return {
      info: {},
      loading:false
    }
  },
   components: {
    // Else
  },
  mounted() {
    this.knowledgeDetail()
    window.parent.postMessage(
                  {
                    data: 1438,
                  },
                  "*"
                );
  },
  computed: {
    companyName() {
      return this.$route.query.companyName
    }
  },
  methods: {
    async knowledgeDetail() {
      this.loading=true;
      let {category, regNum} = this.$route.query;
      let res = await knowledgeDetail({
        category,
        regNum
      })
      if (res.data.code === 200) {
        this.info = res.data.data || {}
      }
      //子向父发送数据
      this.$nextTick(()=>{
        var height =  document.documentElement.scrollHeight;
        console.log(height)
          window.parent.postMessage(
          {
            data: height,
          },
          "*"
        );
      })
      this.loading=false;
    },
    async clickHandle(row){
      let imgUrl = await this.knowledgeAnnouncement(row);
      if(imgUrl) {
        window.open(imgUrl)
      }else{
        this.$message({
          type:'warning',
          message:'图片不存在'
        })
      }
    },
    async knowledgeAnnouncement(row){
      let {TrialNum:trialNum,PageNum:pageNum} = row;
      let res = await knowledgeAnnouncement({
        trialNum,
        pageNum
      })
      return res.data.data.imageUrl || ''
    }
  },
  beforeCreate() {
            document.querySelector('body').setAttribute('style', 'background-color:#ffffff;')
        },
}
</script>

<style scoped>
.brand-list-title {
  font-weight: 500;
  color: #353535;
  line-height: 25px;
  margin-bottom: 30px;

}
.row-tag{
  padding:8px 25px;
  background-color: #FAFAFA;
  height:20px;
  color:#EB5E00;
  line-height:20px;
}
.content-box {
  background: #FFFFFF;
  margin-top: 20px;
  padding: 40px;
}

.row-title {
  font-weight: 500;
  color: #000000;
  line-height: 20px;
  margin: 20px 0;
}

.row-item {
  display: flex;
  font-size: 14px;
}

.row-label {
  min-width: 100px;
  color: #323232;
  margin-bottom: 16px;
}

.row-value {
  color: #666;
}

.process-content {
  position: relative;
  border-left: 1px solid #999990;
  line-height: 20px;
  padding-bottom: 16px;
  padding-left: 18px;
  margin-left: 5px;
  color: #666;
}

.point {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #FFFFFF;
  border: 2px solid #FF5E00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: -6px;
  top: 5px;
}
</style>
